<template>
    <div class="add-service-person">
        <div class="add-service-person-wrapper">
            <div class="add-service-person-wrapper-title">
                <h2>הוספת איש שירות</h2>
                <div @click="$emit('close')" class="exit-btn">
                    <i class="material-icons">close</i>
                </div>
            </div>
            <div class="add-service-person-wrapper-content">
                <div class="add-service-person-wrapper-content-field">
                        <span style="font-size: 20px;">סוג התקלה</span>
                        <el-select
                            style="display: block;"
                            v-model="person_data.type"
                            class="m-2"
                            placeholder="סוג תקלה"
                            size="large"
                        >
                            <el-option
                                v-for="option in errors_options"
                                :key="option"
                                :value="option"
                            />
                        </el-select>
                   
                </div>
                <div class="add-service-person-wrapper-content-field">
                        <span style="font-size: 20px;">שם מלא</span>
                        <el-input v-model="person_data.name" placeholder="שם מלא" />
                </div>
                <div class="add-service-person-wrapper-content-field">
                        <span style="font-size: 20px;">טלפון נייד</span>
                        <el-input type="tel" v-model="person_data.phone" placeholder="טלפון נייד" />
                </div>
                <div class="add-service-person-wrapper-content-field">
                        <span style="font-size: 20px;">אימייל</span>
                        <el-input type="email" v-model="person_data.email" placeholder="אימייל" />
                </div>
            </div>
            <div class="add-service-person-wrapper-footer">
                <el-button v-if="!selected_edit" style="width: 80%;" @click="handle_submit" type="success">הוספה</el-button>
                <el-button v-else style="width: 80%;" @click="handle_submit_edit" type="warning">עדכון</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {slide_pop_error} from '../../../../../Methods/Msgs'
export default {
    props:['errors_options','selected_edit'],
    emits:['close','submit','submit_edit'],
    setup (props,{emit}) {
        
        const err_msg = ref('')

        const person_data = ref({
            type:'',
            name:'',
            phone:'',
            email:''
        })

        const validation = () => {
            for (const [key, value] of Object.entries(person_data.value)) {
                switch (key) {
                    case 'type':
                        if(!value){
                            err_msg.value ='עליך לבחור סוג תקלה!'
                            return false
                        }
                        break;
                    case 'name':
                        if(!value){
                            err_msg.value ='עליך להזין שם איש שירות!'
                            return false
                        }
                        break;
                    case 'phone':
                        if(!validateIsraeliMobilePhone(value)){
                            err_msg.value ='עליך להזין מס טלפון נייד תקין!'
                            return false
                        }
                        break;
                    case 'email':
                        if(!validateEmail(value)){
                            err_msg.value ='עליך להזין כתובת אימייל  תקינה!'
                            return false
                        }
                        break;
                }
            }
            return true
        }

        function validateIsraeliMobilePhone(number) {
                const regex = /^05[0-8]\d{7}$/;
                return regex.test(number);
        }

        function validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        }

        const handle_submit = () => {
            try{
                if(!validation()){
                    throw new Error(err_msg.value)
                }else{
                    emit('submit',person_data.value)
                }
            }catch(err){
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }

        const handle_submit_edit = () => {
            try{
                if(!validation()){
                    throw new Error(err_msg.value)
                }else{
                    emit('submit_edit',person_data.value)
                }
            }catch(err){
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }


        if(props.selected_edit){
            person_data.value = props.selected_edit
            console.log(person_data.value);
        }

        return {
            handle_submit,
            handle_submit_edit,
            person_data,
            err_msg,
        }
    }
}
</script>

<style scoped>
    .add-service-person{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        color: #333;
    }
    .add-service-person-wrapper{
        width: 100%;
        max-width: 500px;
        background: #fff;
        border-radius: 5px;
    }
    .add-service-person-wrapper-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .add-service-person-wrapper-content{
        width: 100%;

    }
    .add-service-person-wrapper-content-field{
        width: 100%;
        padding: 0 5px;
        margin-bottom: 10px;
    }
    .add-service-person-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
</style>